<template>
  <div v-if="dialog && dataConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition"  max-width="60%" style="z-index: 9999 !important">
      <v-card>
        <div class="modal-header">
          <div class="row">
            <h5 class="modal-title col-6" id="formModal">
              {{ $t("title.user_limit_betting_vn") }}
            </h5>
            <!-- <div class="col-6" style="text-align: end;">
              <button
                class="btn btn-primary"
                :disabled="loading"
                text
                @click="validate"
              >
                {{ $t("button.save") }}
              </button>
            </div> -->
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form
              v-if="dataConfig"
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-tabs
                background-color="blue-grey lighten-5 accent-4"
                center-active
              >
                <v-tab v-for="(indexK, index3) in dataConfig.objectVn" :key="index3">
                  <span v-if=" indexK.channel_type == 1">CO1</span>
                  <span v-if=" indexK.channel_type == 2">CO2</span>
                  <span v-if=" indexK.channel_type == 3">CO3</span>
                  <span v-if=" indexK.channel_type == 4">CO4</span>
                  <span v-if=" indexK.channel_type == 15">BA4</span>
                  <span v-if=" indexK.channel_type == 16">ROULLETE</span>
                  <span v-if=" indexK.channel_type == 5">KL1</span>
                  <span v-if=" indexK.channel_type == 6">BA1</span>
                  <span v-if=" indexK.channel_type == 7">BA2</span>
                  <span v-if=" indexK.channel_type == 18">BA3</span>
                  <span v-if=" indexK.channel_type == 8">TD1</span>
                  <span v-if=" indexK.channel_type == 9">TD2</span>
                  <span v-if=" indexK.channel_type == 19">TD3</span>
                  <span v-if=" indexK.channel_type == 10">LO1</span>
                  <span v-if=" indexK.channel_type == 11">YU1</span>
                  <span v-if=" indexK.channel_type == 12">AP1</span>
                  <span v-if=" indexK.channel_type == 17">AP2</span>
                  <span v-if=" indexK.channel_type == 13">FT1</span>
                  <span v-if=" indexK.channel_type == 14">TS1</span>
                  <span v-if=" indexK.channel_type == 20">HAN2</span>
                  <span v-if=" indexK.channel_type == 21">HAN3</span>
                </v-tab>

                  <v-tab-item v-for="(indexOk, index4) in dataConfig.objectVn" :key="index4">
                  <v-card flat>
                    <v-card-text>
                        <v-alert text outlined color="deep-orange"> លុយ វៀតណម </v-alert>

                        <span class="text"> កំណត់ចំនួនដងនៃការចាក់ </span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentVn[index4].betting_frequency
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.betting_frequency"
                          type="number"
                        />

                        <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentVn[index4].total_bet_win_a_day
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.total_bet_win_a_day"
                          type="number"
                        />

                        <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentVn[index4].total_bet_a_fight
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.total_bet_a_fight"
                          type="number"
                        />
                        <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">
                          {{
                            indexOk.currency_type == 1
                              ? 1
                              : indexOk.currency_type == 2
                              ? currencyFormatKh(dataConfig.objectParentVn[index4].amount_min)
                              : indexOk.currency_type == 4
                              ? currencyFormatKh(dataConfig.objectParentVn[index4].amount_min)
                              : "loading.."
                          }}</span
                        >
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.amount_min"
                          type="number"
                        />
                        <span class="text">ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">
                          {{
                            currencyFormatKh(dataConfig.objectParentVn[index4].amount_max)
                          }}</span
                        >
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexOk.amount_max"
                          type="number"
                        />
                    
                    </v-card-text>
                  </v-card>
                  <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(indexOk.channel_type)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
                </v-tab-item>
            </v-tabs>
          </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: [],
    limitConfig: [],
    // dataConfig: {
    //   type: Object,
    //   default: Object,
    // },
    // limitConfig: {
    //   type: Object,
    //   default: Object,
    // },
  },
  watch: {
    dataConfig: function (newV) {
      // this.correctSelectChanel(newV.objectUsd);
      this.correctSelectChanelVn(newV.objectVn);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      itemChannelVn1: [
        {
          id: 10,
          name: "CO1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn2: [
        {
          id: 20,
          name: "CO2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn3: [
        {
          id: 30,
          name: "CO3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn4: [
        {
          id: 40,
          name: "CO4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn5: [
        {
          id: 50,
          name: "KL1",
          children: [
            { id: "51", name: "Default", channel_type: 5, vip_option: 1 },
            { id: "52", name: "VIP 1", channel_type: 5, vip_option: 2 },
            { id: "53", name: " VIP 2", channel_type: 5, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn6: [
        {
          id: 60,
          name: "BA1",
          children: [
            { id: "61", name: "Default", channel_type: 6, vip_option: 1 },
            { id: "62", name: "VIP 1", channel_type: 6, vip_option: 2 },
            { id: "63", name: " VIP 2", channel_type: 6, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn7: [
        {
          id: 70,
          name: "BA2",
          children: [
            { id: "71", name: "Default", channel_type: 7, vip_option: 1 },
            { id: "72", name: "VIP 1", channel_type: 7, vip_option: 2 },
            { id: "73", name: " VIP 2", channel_type: 7, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn8: [
        {
          id: 80,
          name: "TD1",
          children: [
            { id: "81", name: "Default", channel_type: 8, vip_option: 1 },
            { id: "82", name: "VIP 1", channel_type: 8, vip_option: 2 },
            { id: "83", name: " VIP 2", channel_type: 8, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn9: [
        {
          id: 90,
          name: "TD2",
          children: [
            { id: "91", name: "Default", channel_type: 9, vip_option: 1 },
            { id: "92", name: "VIP 1", channel_type: 9, vip_option: 2 },
            { id: "93", name: " VIP 2", channel_type: 9, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn10: [
        {
          id: 100,
          name: "LO1",
          children: [
            { id: "101", name: "Default", channel_type: 10, vip_option: 1 },
            { id: "102", name: "VIP 1", channel_type: 10, vip_option: 2 },
            { id: "103", name: " VIP 2", channel_type: 10, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn11: [
        {
          id: 110,
          name: "YU1",
          children: [
            { id: "111", name: "Default", channel_type: 11, vip_option: 1 },
            { id: "112", name: "VIP 1", channel_type: 11, vip_option: 2 },
            { id: "113", name: " VIP 2", channel_type: 11, vip_option: 3 },
          ],
        },
      ],
      itemChannelVn12: [
        {
          id: 120,
          name: "AP1",
          children: [
            { id: "121", name: "Default", channel_type: 12, vip_option: 1 },
            { id: "122", name: "VIP 1", channel_type: 12, vip_option: 2 },
            { id: "123", name: " VIP 2", channel_type: 12, vip_option: 3 },
          ],
        },
      ],

      selectionChannelVn1: [],
      selectedChannelVn1: [],
      selectionChannelVn2: [],
      selectedChannelVn2: [],
      selectionChannelVn3: [],
      selectedChannelVn3: [],
      selectionChanneVn4: [],
      selectedChanneVn4: [],
      selectionChanneVn5: [],
      selectedChanneVn5: [],
      selectionChanneVn6: [],
      selectedChanneVn6: [],
      selectionChanneVn7: [],
      selectedChanneVn7: [],
      selectionChanneVn8: [],
      selectedChanneVn8: [],
      selectionChanneVn9: [],
      selectedChanneVn9: [],
      selectionChanneVn10: [],
      selectedChanneVn10: [],
      selectionChanneVn11: [],
      selectedChanneVn11: [],
      selectionChanneVn12: [],
      selectedChanneVn12: [],
      selectionChanneVn: [],
    };
  },

  methods: {
    validate(channel_type) {
      if (this.$refs.form.validate()) {
      
        this.$emit("submit",channel_type);
        // for kh
        this.selectionChanneVn = [];
        this.selectionChanneVn1 = [];
        this.selectedChanneVn1 = [];
        this.selectionChanneVn2 = [];
        this.selectedChanneVn2 = [];
        this.selectionChanneVn3 = [];
        this.selectedChanneVn3 = [];
        this.selectionChanneVn4 = [];
        this.selectedChanneVn4 = [];
        this.selectionChanneVn5 = [];
        this.selectedChanneVn5 = [];
        this.selectionChanneVn6 = [];
        this.selectedChanneVn6 = [];
        this.selectionChanneVn7 = [];
        this.selectedChanneVn7 = [];
        this.selectionChanneVn8 = [];
        this.selectedChanneVn8 = [];
        this.selectionChanneVn9 = [];
        this.selectedChanneVn9 = [];
        this.selectionChanneVn10 = [];
        this.selectedChanneVn10 = [];
        this.selectionChanneVn11 = [];
        this.selectedChanneVn11 = [];
        this.selectionChanneVn12 = [];
        this.selectedChanneVn12 = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanelVn(item) {
      for (let t in item) {
        if (
          item[t].channel_type == 1 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn1.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 2 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn2.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 3 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn3.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 4 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn4.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type ==  5&&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn5.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 6 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn6.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 7 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn7.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 8 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn8.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 9 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn9.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 10 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn10.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 11 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn11.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 12 &&
          item[t].currency_type == 4
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChanneVn12.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChanneVn1 = this.selectionChanneVn1;
      this.selectedChanneVn2 = this.selectionChanneVn2;
      this.selectedChanneVn3 = this.selectionChanneVn3;
      this.selectedChanneVn4 = this.selectionChanneVn4;
      this.selectedChanneVn5 = this.selectionChanneVn5;
      this.selectedChanneVn6 = this.selectionChanneVn6;
      this.selectedChanneVn7 = this.selectionChanneVn7;
      this.selectedChanneVn8 = this.selectionChanneVn8;
      this.selectedChanneVn9 = this.selectionChanneVn9;
      this.selectedChanneVn10 = this.selectionChanneVn10;
      this.selectedChanneVn11 = this.selectionChanneVn11;
      this.selectedChanneVn12 = this.selectionChanneVn12;
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
