<template>
  <div v-if="dialog && dataConfig">
    <v-dialog
      :value="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="50%"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{ $t("title.user_limit_betting") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <Loading :visible="loading" />
        <br />
        <v-card-text>
          <h4 v-if="user.is_controller == 1" style="color: red;padding-left: 17px;"><strong> Controller not allow to edit !</strong></h4>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation class="form">
              <v-tabs v-model="tab" background-color="blue-grey lighten-5 accent-4" center-active>
                <v-tab  v-for="(channel,channelId) of dataConfig" :key="channelId"
                        @click="onClickLoadChannel(channel)">{{ renameChannelFromAPI(channel.channel_type) }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(object,channelId2) of dataConfig"
                    :key="channelId2"
                >
                  <v-card flat>
                    <div class="form-group">
                      <label>
                        Maximum win a day limit (ឈ្នះធំបំផុតក្នុង១ថ្ងៃ)
                        <span style="color: blue; font-size: larger">
                    {{ currencyFormatKh(limitConfig.total_bet_win_a_day) }}

                  </span>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-money-bill"></i>
                          </div>
                        </div>
                        <input
                            class="form-control currency"
                            v-model="object.total_bet_win_a_day"
                            v-bind:options="options"
                            type="number"
                            :disabled="user.is_controller == 1 ? true : false"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label>
                        Total Bet for 1 Fight (ភ្នាល់ធំបំផុតក្នុង១គូរ)
                        <span style="color: blue; font-size: larger">
                    {{ currencyFormatKh(limitConfig.total_bet_a_fight) }}
                  </span>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-money-bill"></i>
                          </div>
                        </div>
                        <!--vuetify-money-tag-->
                        <input
                            class="form-control currency"
                            v-model="object.total_bet_a_fight"
                            v-bind:options="options"
                            type="number"
                            :disabled="user.is_controller == 1 ? true : false"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label>
                        Minimum bet per 1 invoice (ភ្នាល់តូចបំផុតក្នុង១ប៉ុង)
                        <span style="color: blue; font-size: larger">
                    {{
                            object.currency_type == 1
                                ? 1
                                : object.currency_type == 2
                                ? currencyFormatKh(limitConfig.amount_min)
                                : object.currency_type == 4
                                ? currencyFormatKh(limitConfig.amount_min)
                                : "loading.."
                          }}
                  </span>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-money-bill"></i>
                          </div>
                        </div>
                        <input
                            class="form-control currency"
                            v-model="object.amount_min"
                            v-bind:options="options"
                            type="number"
                            :disabled="user.is_controller == 1 ? true : false"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label>
                        Maximum bet per 1 invoice (ភ្នាល់ធំបំផុតក្នុង១ប៉ុង)
                        <span style="color: blue; font-size: larger">
                    {{ currencyFormatKh(limitConfig.amount_max) }}
                  </span>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-money-bill"></i>
                          </div>
                        </div>
                        <input
                            class="form-control currency"
                            v-model="object.amount_max"
                            v-bind:options="options"
                            type="number"
                            :disabled="user.is_controller == 1 ? true : false"
                        />
                      </div>
                    </div>
                  </v-card>
                  <div class="text-left">
                    <button
                      type="button"
                      class="btn btn-primary m-t-15 waves-effect"
                      :disabled="loading"
                      text
                      @click="validate(object.channel_type)"
                    >
                      <i class="far fa-save"></i>
                      Save
                    </button>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: [],
    //limitConfig: [],
    // dataConfig: {
    //   type: Object,
    //   default: Object,
    // },
    limitConfig: {
      type: Object,
      default: Object,
    },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
    showChannel1() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 1) {
          return true;
        }
      }
      return false;
    },
    showChannel2() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 2) {
          return true;
        }
      }
      return false;
    },
    showChannel3() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 3) {
          return true;
        }
      }
      return false;
    },
    showChannel4() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 4) {
          return true;
        }
      }
      return false;
    },
    showChannel5() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 5) {
          return true;
        }
      }
      return false;
    },
    showChannel6() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 6) {
          return true;
        }
      }
      return false;
    },
    showChannel7() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 7) {
          return true;
        }
      }
      return false;
    },
    showChannel8() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 8) {
          return true;
        }
      }
      return false;
    },
    showChannel9() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 9) {
          return true;
        }
      }
      return false;
    },
    showChannel10() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 10) {
          return true;
        }
      }
      return false;
    },
    showChannel11() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 11) {
          return true;
        }
      }
      return false;
    },
    showChannel12() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 12) {
          return true;
        }
      }
      return false;
    },
  },
  data: () => {
    return {
      showPassword: false,
      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannelu5: [],
      selectedChannelu5: [],
      selectionChannelu6: [],
      selectedChannelu6: [],
      selectionChannelu7: [],
      selectedChannelu7: [],
      selectionChannelu8: [],
      selectedChannelu8: [],
      selectionChannelu9: [],
      selectedChannelu9: [],
      selectionChannelu10: [],
      selectedChannelu10: [],
      selectionChannelu11: [],
      selectedChannelu11: [],
      selectionChannelu12: [],
      selectedChannelu12: [],

      selectionChannel: [],
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      selectedCh: [],
      vipChanel1: [
        {
          id: 11,
          vip_option: 1,
          channel_type: 1,
        },
      ],
      vipChanel2: [
        {
          id: 21,
          vip_option: 1,
          channel_type: 2,
        },
      ],

      vipChanel3: [
        {
          id: 31,
          vip_option: 1,
          channel_type: 3,
        },
      ],
      vipChanel4: [
        {
          id: 41,
          vip_option: 1,
          channel_type: 4,
        },
      ],
      vipChanel5: [
        {
          id: 51,
          vip_option: 1,
          channel_type: 5,
        },
      ],
      vipChanel6: [
        {
          id: 61,
          vip_option: 1,
          channel_type: 6,
        },
      ],
      vipChanel7: [
        {
          id: 71,
          vip_option: 1,
          channel_type: 7,
        },
      ],
      vipChanel8: [
        {
          id: 81,
          vip_option: 1,
          channel_type: 8,
        },
      ],
      vipChanel9: [
        {
          id: 91,
          vip_option: 1,
          channel_type: 9,
        },
      ],
      vipChanel10: [
        {
          id: 101,
          vip_option: 1,
          channel_type: 10,
        },
      ],
      vipChanel11: [
        {
          id: 111,
          vip_option: 1,
          channel_type: 11,
        },
      ],
      vipChanel12: [
        {
          id: 121,
          vip_option: 1,
          channel_type: 12,
        },
      ],
      tab:null
    };
  },
  methods: {
    onClickLoadChannel(channel){
      this.$emit("changeTab",channel.channel_type);
      let temArray = [];
      for (let j in this.dataConfig) {
        //console.log('this.limitConfig.channel_allow[j].channel_type..= ', this.limitConfig.channel_allow[j].channel_type);
        temArray.push(this.dataConfig[j].channel_type);

      }
      let channelUniqueList = [...new Set(temArray)];
      if(channelUniqueList.includes(channel.channel_type) == true) {
        this.channelCheck == true;
      } else {
        this.channelCheck == false;
      }
    },
    renameChannelFromAPI(channelType){
      if(channelType == 1) return "CO1";
      else if(channelType == 2) return "Co2";
      else if(channelType == 3) return "Co3";
      else if(channelType == 4) return "Co4";
      else if(channelType == 15) return "BA4";
      else if(channelType == 16) return "ROULLETE";
      else if(channelType == 5) return "KL1";
      else if(channelType == 6) return "BA1";
      else if(channelType == 7) return "BA2";
      else if(channelType == 18) return "BA3";
      else if(channelType == 8) return "TD1";
      else if(channelType == 9) return "TD2";
      else if(channelType == 19) return "TD3";
      else if(channelType == 10) return "LO1";
      else if(channelType == 11) return "YU1";
      else if(channelType == 12) return "AP1";
      else if(channelType == 17) return "AP2";
      else if(channelType == 13) return "FT1";
      else if(channelType == 14) return "TS1";
      else if(channelType == 20) return "HAN2";
      else if(channelType == 21) return "HAN3";
    },
    validate(channel_type) {
      if (this.$refs.form.validate()) {
        console.log("channel_type",channel_type);
      
        this.$emit("submit", channel_type);
        this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
        this.selectionChannelu5 = [];
        this.selectedChannelu5 = [];
        this.selectionChannelu6 = [];
        this.selectedChannelu6 = [];
        this.selectionChannelu7 = [];
        this.selectedChannelu7 = [];
        this.selectionChannelu8 = [];
        this.selectedChannelu8 = [];
        this.selectionChannelu9 = [];
        this.selectedChannelu9 = [];
        this.selectionChannelu10 = [];
        this.selectedChannelu10 = [];
        this.selectionChannelu11 = [];
        this.selectedChannelu11 = [];
        this.selectionChannelu12 = [];
        this.selectedChannelu12 = [];
        this.dataConfig.channel_allow = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanel(item) {
      this.selectedCh = [];
      for (let i in item.channel_allow) {
        if (item.channel_allow[i].channel_type == 1) {
          this.selectedCh.channel_1 = true;
        }
        if (item.channel_allow[i].channel_type == 2) {
          this.selectedCh.channel_2 = true;
        }
        if (item.channel_allow[i].channel_type == 3) {
          this.selectedCh.channel_3 = true;
        }
        if (item.channel_allow[i].channel_type == 4) {
          this.selectedCh.channel_4 = true;
        }
        if (item.channel_allow[i].channel_type == 5) {
          this.selectedCh.channel_5 = true;
        }
        if (item.channel_allow[i].channel_type == 6) {
          this.selectedCh.channel_6 = true;
        }
        if (item.channel_allow[i].channel_type == 7) {
          this.selectedCh.channel_7 = true;
        }
        if (item.channel_allow[i].channel_type == 8) {
          this.selectedCh.channel_8 = true;
        }
        if (item.channel_allow[i].channel_type == 9) {
          this.selectedCh.channel_9 = true;
        }
        if (item.channel_allow[i].channel_type == 10) {
          this.selectedCh.channel_10 = true;
        }
        if (item.channel_allow[i].channel_type == 11) {
          this.selectedCh.channel_11 = true;
        }
        if (item.channel_allow[i].channel_type == 12) {
          this.selectedCh.channel_12 = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
