<template>
  <div>
    <v-row justify="center">
      <div v-if="dialog && dataConfig">
        <v-dialog
          :value="dialog"
          persistent
          max-width="50%"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-btn icon @click="$emit('onCloseDialog')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase">
                {{
                  !dataConfig._id ? $t("title.configSlotLimit") : $t("title.configSlotLimit")
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <!-- <v-toolbar-items>
                <v-btn :disabled="loading" text @click="validate">
                  {{ !dataConfig._id ? $t("button.create") : $t("button.update") }}
                </v-btn>
              </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-tabs v-model="tab" background-color="blue-grey lighten-5 accent-4" center-active>
                    <v-tab  v-for="(channel,channelId) of dataConfig" :key="channelId">{{channel.name}}</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(object,channelId2) of dataConfig"
                      :key="channelId2">
                      <v-card flat>
                        <div class="form-group">
                          <v-row v-for="(indexData, index) in object.sub_menus" :key="index">
                          <v-col cols="5" sm="5" md="5" style="padding:0px;">
                            <v-checkbox style="padding-left:12px" v-model="indexData.enable" 
                              :label="indexData.channel_type ==90 ? 'ដាក់ដំណើរការ(Lotto 160s)' 
                                  :indexData.channel_type ==901 ? 'ដាក់ដំណើរការ(Lotto 100s)'
                                  :indexData.channel_type ==902 ? 'ដាក់ដំណើរការ(Lotto 30s)'
                                  :indexData.channel_type ==91 ? 'ដាក់ដំណើរការ(Yuki 160s)'
                                  :indexData.channel_type ==911 ? 'ដាក់ដំណើរការ(Yuki 100s)'
                                  :indexData.channel_type ==912 ? 'ដាក់ដំណើរការ(Yuki 30s)'
                                  :indexData.channel_type ==92 ? 'ដាក់ដំណើរការ(LOTTERY 160s)'
                                  :indexData.channel_type ==921 ? 'ដាក់ដំណើរការ(LOTTERY 100s)'
                                  :indexData.channel_type ==922 ? 'ដាក់ដំណើរការ(LOTTERY 30s)'
                                  :indexData.channel_type ==89 ? 'ដាក់ដំណើរការ(LOTTERY3D 160s)'
                                  :indexData.channel_type ==891 ? 'ដាក់ដំណើរការ(LOTTERY3D 100s)'
                                  :indexData.channel_type ==892 ? 'ដាក់ដំណើរការ(LOTTERY3D 30s)'
                                  :indexData.channel_type ==99 ? 'ដាក់ដំណើរការ(KENO 160s)'
                                  :indexData.channel_type ==991 ? 'ដាក់ដំណើរការ(KENO 100s)'
                                  :indexData.channel_type ==992 ? 'ដាក់ដំណើរការ(KENO 30s)'
                                  :indexData.channel_type ==93 ? 'ដាក់ដំណើរការ(KLAKLOUK 60s)'
                                  :indexData.channel_type ==931 ? 'ដាក់ដំណើរការ(KLAKLOUK 25s)'
                                  :indexData.channel_type ==932 ? 'ដាក់ដំណើរការ(KLAKLOUK 25s)'
                                  :indexData.channel_type ==933 ? 'ដាក់ដំណើរការ(KLAKLOUK 15s)'
                                  :indexData.channel_type ==934 ? 'ដាក់ដំណើរការ(KLAKLOUK 15s)'
                                  :indexData.channel_type ==94 ? 'ដាក់ដំណើរការ(DRAGON 60s)'
                                  :indexData.channel_type ==941 ? 'ដាក់ដំណើរការ(DRAGON 60s)'
                                  :indexData.channel_type ==942 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                  :indexData.channel_type ==943 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                  :indexData.channel_type ==944 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                  :indexData.channel_type ==945 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                  :indexData.channel_type ==946 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                  :indexData.channel_type ==947 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                  :indexData.channel_type ==948 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                  :indexData.channel_type ==949 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                  :indexData.channel_type ==95 ? 'ដាក់ដំណើរការ(BACCARAT 60s)'
                                  :indexData.channel_type ==951 ? 'ដាក់ដំណើរការ(BACCARAT 60s)'
                                  :indexData.channel_type ==952 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                  :indexData.channel_type ==953 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                  :indexData.channel_type ==954 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                  :indexData.channel_type ==955 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                  :indexData.channel_type ==956 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                  :indexData.channel_type ==957 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                  :indexData.channel_type ==958 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                  :indexData.channel_type ==959 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                  :indexData.channel_type ==96 ? 'ដាក់ដំណើរការ(DODEN 60s)'
                                  :indexData.channel_type ==961 ? 'ដាក់ដំណើរការ(DODEN 25s)'
                                  :indexData.channel_type ==962 ? 'ដាក់ដំណើរការ(DODEN 25s)'
                                  :indexData.channel_type ==963 ? 'ដាក់ដំណើរការ(DODEN 15s)'
                                  :indexData.channel_type ==964 ? 'ដាក់ដំណើរការ(DODEN 15s)'
                                  :indexData.channel_type ==97 ? 'ដាក់ដំណើរការ(SICBO 60s)'
                                  :indexData.channel_type ==971 ? 'ដាក់ដំណើរការ(SICBO 25s)'
                                  :indexData.channel_type ==972 ? 'ដាក់ដំណើរការ(SICBO 25s)'
                                  :indexData.channel_type ==973 ? 'ដាក់ដំណើរការ(SICBO 15s)'
                                  :indexData.channel_type ==974 ? 'ដាក់ដំណើរការ(SICBO 15s)'
                                  :indexData.channel_type ==98 ? 'ដាក់ដំណើរការ(TAIXIU 60s)'
                                  :indexData.channel_type ==981 ? 'ដាក់ដំណើរការ(TAIXIU 25s)'
                                  :indexData.channel_type ==982 ? 'ដាក់ដំណើរការ(TAIXIU 25s)'
                                  :indexData.channel_type ==983 ? 'ដាក់ដំណើរការ(TAIXIU 15s)'
                                  :indexData.channel_type ==984 ? 'ដាក់ដំណើរការ(TAIXIU 15s)'
                                  :''">
                            </v-checkbox>
                            </v-col>
                            <v-col cols="7">
                              <v-text-field
                                v-model="indexData.total_bet_win_a_day"
                                :rules="indexData.channel_type ==90 ? rules.total_bet_win_a_day_lotto_160
                                  :indexData.channel_type ==901 ? rules.total_bet_win_a_day_lotto_100
                                  :indexData.channel_type ==902 ? rules.total_bet_win_a_day_lotto_30
                                  :indexData.channel_type ==91 ? rules.total_bet_win_a_day_yuki_160
                                  :indexData.channel_type ==911 ? rules.total_bet_win_a_day_yuki_100
                                  :indexData.channel_type ==912 ? rules.total_bet_win_a_day_yuki_30
                                  :indexData.channel_type ==92 ? rules.total_bet_win_a_day_pp78_160
                                  :indexData.channel_type ==921 ? rules.total_bet_win_a_day_pp78_100
                                  :indexData.channel_type ==922 ? rules.total_bet_win_a_day_pp78_30
                                  :indexData.channel_type ==93 ? rules.total_bet_win_a_day_klaklouk_60
                                  :indexData.channel_type ==931 ? rules.total_bet_win_a_day_klaklouk_251
                                  :indexData.channel_type ==932 ? rules.total_bet_win_a_day_klaklouk_252
                                  :indexData.channel_type ==933 ? rules.total_bet_win_a_day_klaklouk_151
                                  :indexData.channel_type ==934 ? rules.total_bet_win_a_day_klaklouk_152
                                  :indexData.channel_type ==94 ? rules.total_bet_win_a_day_dragon_601
                                  :indexData.channel_type ==941 ? rules.total_bet_win_a_day_dragon_602
                                  :indexData.channel_type ==942 ? rules.total_bet_win_a_day_dragon_251
                                  :indexData.channel_type ==943 ? rules.total_bet_win_a_day_dragon_252
                                  :indexData.channel_type ==944 ? rules.total_bet_win_a_day_dragon_253
                                  :indexData.channel_type ==945 ? rules.total_bet_win_a_day_dragon_254
                                  :indexData.channel_type ==946 ? rules.total_bet_win_a_day_dragon_151
                                  :indexData.channel_type ==947 ? rules.total_bet_win_a_day_dragon_152
                                  :indexData.channel_type ==948 ? rules.total_bet_win_a_day_dragon_153
                                  :indexData.channel_type ==949 ? rules.total_bet_win_a_day_dragon_154
                                  :indexData.channel_type ==95 ? rules.total_bet_win_a_day_baccarat_601
                                  :indexData.channel_type ==951 ? rules.total_bet_win_a_day_baccarat_602
                                  :indexData.channel_type ==952 ? rules.total_bet_win_a_day_baccarat_251
                                  :indexData.channel_type ==953 ? rules.total_bet_win_a_day_baccarat_252
                                  :indexData.channel_type ==954 ? rules.total_bet_win_a_day_baccarat_253
                                  :indexData.channel_type ==955 ? rules.total_bet_win_a_day_baccarat_254
                                  :indexData.channel_type ==956 ? rules.total_bet_win_a_day_baccarat_151
                                  :indexData.channel_type ==957 ? rules.total_bet_win_a_day_baccarat_152
                                  :indexData.channel_type ==958 ? rules.total_bet_win_a_day_baccarat_153
                                  :indexData.channel_type ==959 ? rules.total_bet_win_a_day_baccarat_154
                                  :indexData.channel_type ==96 ? rules.total_bet_win_a_day_doden_60
                                  :indexData.channel_type ==961 ? rules.total_bet_win_a_day_doden_251
                                  :indexData.channel_type ==962 ? rules.total_bet_win_a_day_doden_252
                                  :indexData.channel_type ==963 ? rules.total_bet_win_a_day_doden_151
                                  :indexData.channel_type ==964 ? rules.total_bet_win_a_day_doden_152
                                  :indexData.channel_type ==97 ? rules.total_bet_win_a_day_sicbo_60
                                  :indexData.channel_type ==971 ? rules.total_bet_win_a_day_sicbo_251
                                  :indexData.channel_type ==972 ? rules.total_bet_win_a_day_sicbo_252
                                  :indexData.channel_type ==973 ? rules.total_bet_win_a_day_sicbo_151
                                  :indexData.channel_type ==974 ? rules.total_bet_win_a_day_sicbo_152
                                  :indexData.channel_type ==98 ? rules.total_bet_win_a_day_taixiu_60
                                  :indexData.channel_type ==981 ? rules.total_bet_win_a_day_taixiu_251
                                  :indexData.channel_type ==982 ? rules.total_bet_win_a_day_taixiu_252
                                  :indexData.channel_type ==983 ? rules.total_bet_win_a_day_taixiu_151
                                  :indexData.channel_type ==984 ? rules.total_bet_win_a_day_taixiu_152
                                  :indexData.channel_type ==99 ? rules.total_bet_win_a_day_keno_160
                                  :indexData.channel_type ==991 ? rules.total_bet_win_a_day_keno_100
                                  :indexData.channel_type ==992 ? rules.total_bet_win_a_day_keno_30
                                  :indexData.channel_type ==89 ? rules.total_bet_win_a_day_lottery3d_160
                                  :indexData.channel_type ==891 ? rules.total_bet_win_a_day_lottery3d_100
                                  :indexData.channel_type ==892 ? rules.total_bet_win_a_day_lottery3d_30
                                  :''"
                                type="number"
                                :label="indexData.channel_type ==90 ? $t('input.total_bet_win_a_day_lotto160') 
                                  :indexData.channel_type ==901 ? $t('input.total_bet_win_a_day_lotto100') 
                                  :indexData.channel_type ==902 ? $t('input.total_bet_win_a_day_lotto30') 
                                  :indexData.channel_type ==91 ? $t('input.total_bet_win_a_day_yuki160')
                                  :indexData.channel_type ==911 ? $t('input.total_bet_win_a_day_yuki100')
                                  :indexData.channel_type ==912 ? $t('input.total_bet_win_a_day_yuki30')
                                  :indexData.channel_type ==92 ? $t('input.total_bet_win_a_day_pp78160')
                                  :indexData.channel_type ==921 ? $t('input.total_bet_win_a_day_pp78100')
                                  :indexData.channel_type ==922 ? $t('input.total_bet_win_a_day_pp7830')
                                  :indexData.channel_type ==93 ? $t('input.total_bet_win_a_day_klaklouk60')
                                  :indexData.channel_type ==931 ? $t('input.total_bet_win_a_day_klaklouk25')
                                  :indexData.channel_type ==932 ? $t('input.total_bet_win_a_day_klaklouk25')
                                  :indexData.channel_type ==933 ? $t('input.total_bet_win_a_day_klaklouk15')
                                  :indexData.channel_type ==934 ? $t('input.total_bet_win_a_day_klaklouk15')
                                  :indexData.channel_type ==94 ? $t('input.total_bet_win_a_day_dragon60')
                                  :indexData.channel_type ==941 ? $t('input.total_bet_win_a_day_dragon60')
                                  :indexData.channel_type ==942 ? $t('input.total_bet_win_a_day_dragon25')
                                  :indexData.channel_type ==943 ? $t('input.total_bet_win_a_day_dragon25')
                                  :indexData.channel_type ==944 ? $t('input.total_bet_win_a_day_dragon25')
                                  :indexData.channel_type ==945 ? $t('input.total_bet_win_a_day_dragon25')
                                  :indexData.channel_type ==946 ? $t('input.total_bet_win_a_day_dragon15')
                                  :indexData.channel_type ==947 ? $t('input.total_bet_win_a_day_dragon15')
                                  :indexData.channel_type ==948 ? $t('input.total_bet_win_a_day_dragon15')
                                  :indexData.channel_type ==949 ? $t('input.total_bet_win_a_day_dragon15')
                                  :indexData.channel_type ==95 ? $t('input.total_bet_win_a_day_baccarat60')
                                  :indexData.channel_type ==951 ? $t('input.total_bet_win_a_day_baccarat60')
                                  :indexData.channel_type ==952 ? $t('input.total_bet_win_a_day_baccarat25')
                                  :indexData.channel_type ==953 ? $t('input.total_bet_win_a_day_baccarat25')
                                  :indexData.channel_type ==954 ? $t('input.total_bet_win_a_day_baccarat25')
                                  :indexData.channel_type ==955 ? $t('input.total_bet_win_a_day_baccarat25')
                                  :indexData.channel_type ==956 ? $t('input.total_bet_win_a_day_baccarat15')
                                  :indexData.channel_type ==957 ? $t('input.total_bet_win_a_day_baccarat15')
                                  :indexData.channel_type ==958 ? $t('input.total_bet_win_a_day_baccarat15')
                                  :indexData.channel_type ==959 ? $t('input.total_bet_win_a_day_baccarat15')
                                  :indexData.channel_type ==96 ? $t('input.total_bet_win_a_day_doden60')
                                  :indexData.channel_type ==961 ? $t('input.total_bet_win_a_day_doden25')
                                  :indexData.channel_type ==962 ? $t('input.total_bet_win_a_day_doden25')
                                  :indexData.channel_type ==963 ? $t('input.total_bet_win_a_day_doden15')
                                  :indexData.channel_type ==964 ? $t('input.total_bet_win_a_day_doden15')
                                  :indexData.channel_type ==97 ? $t('input.total_bet_win_a_day_sicbo60')
                                  :indexData.channel_type ==971 ? $t('input.total_bet_win_a_day_sicbo25')
                                  :indexData.channel_type ==972 ? $t('input.total_bet_win_a_day_sicbo25')
                                  :indexData.channel_type ==973 ? $t('input.total_bet_win_a_day_sicbo15')
                                  :indexData.channel_type ==974 ? $t('input.total_bet_win_a_day_sicbo15')
                                  :indexData.channel_type ==98 ? $t('input.total_bet_win_a_day_taixiu60')
                                  :indexData.channel_type ==981 ? $t('input.total_bet_win_a_day_taixiu25')
                                  :indexData.channel_type ==982 ? $t('input.total_bet_win_a_day_taixiu25')
                                  :indexData.channel_type ==983 ? $t('input.total_bet_win_a_day_taixiu15')
                                  :indexData.channel_type ==984 ? $t('input.total_bet_win_a_day_taixiu15')
                                  :indexData.channel_type ==99 ? $t('input.total_bet_win_a_day_keno160')
                                  :indexData.channel_type ==991 ? $t('input.total_bet_win_a_day_keno100')
                                  :indexData.channel_type ==992 ? $t('input.total_bet_win_a_day_keno30')
                                  :indexData.channel_type ==89 ? $t('input.total_bet_win_a_day_lot3160')
                                  :indexData.channel_type ==891 ? $t('input.total_bet_win_a_day_lot3100')
                                  :indexData.channel_type ==892 ? $t('input.total_bet_win_a_day_lot330')
                                  :''"
                                hide-details="auto"
                              />
                            </v-col>
                          </v-row> 
                        </div>
                        <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(object.name)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
                  </v-card>

                  </v-tab-item>
                  </v-tabs-items>

                  <!-- <v-row v-for="(indexData, index) in dataConfig" :key="index">
                    <v-col cols="4" sm="4" md="4" style="padding:0px;">
                    <v-checkbox v-model="indexData.enable" 
                      :label="indexData.channel_type ==90 ? 'ដាក់ដំណើរការ(Lotto 160s)' 
                          :indexData.channel_type ==901 ? 'ដាក់ដំណើរការ(Lotto 100s)'
                          :indexData.channel_type ==902 ? 'ដាក់ដំណើរការ(Lotto 30s)'
                          :indexData.channel_type ==91 ? 'ដាក់ដំណើរការ(Yuki 160s)'
                          :indexData.channel_type ==911 ? 'ដាក់ដំណើរការ(Yuki 100s)'
                          :indexData.channel_type ==912 ? 'ដាក់ដំណើរការ(Yuki 30s)'
                          :indexData.channel_type ==92 ? 'ដាក់ដំណើរការ(LOTTERY)'
                          :indexData.channel_type ==93 ? 'ដាក់ដំណើរការ(KLAKLOUK)'
                          :indexData.channel_type ==94 ? 'ដាក់ដំណើរការ(DRAGON)'
                          :indexData.channel_type ==95 ? 'ដាក់ដំណើរការ(BACCARAT)'
                          :indexData.channel_type ==96 ? 'ដាក់ដំណើរការ(DODEN)'
                          :indexData.channel_type ==97 ? 'ដាក់ដំណើរការ(SICBO)'
                          :indexData.channel_type ==98 ? 'ដាក់ដំណើរការ(TAIXIU)'
                          :indexData.channel_type ==99 ? 'ដាក់ដំណើរការ(KENO 160s)'
                          :indexData.channel_type ==991 ? 'ដាក់ដំណើរការ(KENO 100s)'
                          :indexData.channel_type ==992 ? 'ដាក់ដំណើរការ(KENO 30s)'
                          :''">
                    </v-checkbox>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="indexData.total_bet_win_a_day"
                        :rules="indexData.channel_type ==90 ? rules.total_bet_win_a_day_lotto_160
                          :indexData.channel_type ==901 ? rules.total_bet_win_a_day_lotto_100
                          :indexData.channel_type ==902 ? rules.total_bet_win_a_day_lotto_30
                          :indexData.channel_type ==91 ? rules.total_bet_win_a_day_yuki_160
                          :indexData.channel_type ==911 ? rules.total_bet_win_a_day_yuki_100
                          :indexData.channel_type ==912 ? rules.total_bet_win_a_day_yuki_30
                          :indexData.channel_type ==92 ? rules.total_bet_win_a_day_pp78
                          :indexData.channel_type ==93 ? rules.total_bet_win_a_day_klaklouk
                          :indexData.channel_type ==94 ? rules.total_bet_win_a_day_dragon
                          :indexData.channel_type ==95 ? rules.total_bet_win_a_day_baccarat
                          :indexData.channel_type ==96 ? rules.total_bet_win_a_day_doden
                          :indexData.channel_type ==97 ? rules.total_bet_win_a_day_sicbo
                          :indexData.channel_type ==98 ? rules.total_bet_win_a_day_taixiu
                          :indexData.channel_type ==99 ? rules.total_bet_win_a_day_keno_160
                          :indexData.channel_type ==991 ? rules.total_bet_win_a_day_keno_100
                          :indexData.channel_type ==992 ? rules.total_bet_win_a_day_keno_30
                          :''"
                        type="number"
                        :label="indexData.channel_type ==90 ? $t('input.total_bet_win_a_day_lotto160') 
                          :indexData.channel_type ==901 ? $t('input.total_bet_win_a_day_lotto100') 
                          :indexData.channel_type ==902 ? $t('input.total_bet_win_a_day_lotto30') 
                          :indexData.channel_type ==91 ? $t('input.total_bet_win_a_day_yuki160')
                          :indexData.channel_type ==911 ? $t('input.total_bet_win_a_day_yuki100')
                          :indexData.channel_type ==912 ? $t('input.total_bet_win_a_day_yuki30')
                          :indexData.channel_type ==92 ? $t('input.total_bet_win_a_day_pp78')
                          :indexData.channel_type ==93 ? $t('input.total_bet_win_a_day_klaklouk')
                          :indexData.channel_type ==94 ? $t('input.total_bet_win_a_day_dragon')
                          :indexData.channel_type ==95 ? $t('input.total_bet_win_a_day_baccarat')
                          :indexData.channel_type ==96 ? $t('input.total_bet_win_a_day_doden')
                          :indexData.channel_type ==97 ? $t('input.total_bet_win_a_day_sicbo')
                          :indexData.channel_type ==98 ? $t('input.total_bet_win_a_day_taixiu')
                          :indexData.channel_type ==99 ? $t('input.total_bet_win_a_day_keno160')
                          :indexData.channel_type ==991 ? $t('input.total_bet_win_a_day_keno100')
                          :indexData.channel_type ==992 ? $t('input.total_bet_win_a_day_keno30')
                          :''"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row> -->
                  <!-- <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(object.name)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div> -->
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    darkmode: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          total_bet_win_a_day_lotto_160: [(v) => !!v || "Total Lotto is required", (v) =>
              (!!v && v > 0) ||
              "Total Lotto greater than 0"],
          total_bet_win_a_day_lotto_100: [(v) => !!v || "Total Lotto is required", (v) =>
              (!!v && v > 0) ||
              "Total Lotto greater than 0"],
          total_bet_win_a_day_lotto_30: [(v) => !!v || "Total Lotto is required", (v) =>
              (!!v && v > 0) ||
              "Total Lotto greater than 0"],
          total_bet_win_a_day_yuki_160: [(v) => !!v || "Total Yuki is required",
          (v) => (!!v && v > 0) ||
              "Total Yuki greater than 0"],
          total_bet_win_a_day_yuki_100: [(v) => !!v || "Total Yuki is required",
          (v) => (!!v && v > 0) ||
              "Total Yuki greater than 0"],
          total_bet_win_a_day_yuki_30: [(v) => !!v || "Total Yuki is required",
          (v) => (!!v && v > 0) ||
              "Total Yuki greater than 0"],
          total_bet_win_a_day_pp78_160: [(v) => !!v || "Total PP78 is required",
          (v) => (!!v && v > 0) ||
              "Total PP78 greater than 0"],
          total_bet_win_a_day_pp78_100: [(v) => !!v || "Total PP78 is required",
          (v) => (!!v && v > 0) ||
              "Total PP78 greater than 0"],
          total_bet_win_a_day_pp78_30: [(v) => !!v || "Total PP78 is required",
          (v) => (!!v && v > 0) ||
              "Total PP78 greater than 0"],
          total_bet_win_a_day_klaklouk_60: [(v) => !!v || "Total Klaklouk 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Klaklouk 60s greater than 0"],
          total_bet_win_a_day_klaklouk_251: [(v) => !!v || "Total Klaklouk 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Klaklouk 25s greater than 0"],
          total_bet_win_a_day_klaklouk_252: [(v) => !!v || "Total Klaklouk 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Klaklouk 25s greater than 0"],
          total_bet_win_a_day_klaklouk_151: [(v) => !!v || "Total Klaklouk 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Klaklouk 15s greater than 0"],
          total_bet_win_a_day_klaklouk_152: [(v) => !!v || "Total Klaklouk 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Klaklouk 15s greater than 0"],
          total_bet_win_a_day_dragon_601: [(v) => !!v || "Total Dragon 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 60s greater than 0"],
          total_bet_win_a_day_dragon_602: [(v) => !!v || "Total Dragon 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 60s greater than 0"],
          total_bet_win_a_day_dragon_251: [(v) => !!v || "Total Dragon 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 25s greater than 0"],
          total_bet_win_a_day_dragon_252: [(v) => !!v || "Total Dragon 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 25s greater than 0"],
          total_bet_win_a_day_dragon_253: [(v) => !!v || "Total Dragon 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 25s greater than 0"],
          total_bet_win_a_day_dragon_254: [(v) => !!v || "Total Dragon 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 25s greater than 0"],
          total_bet_win_a_day_dragon_151: [(v) => !!v || "Total Dragon 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 15s greater than 0"],
          total_bet_win_a_day_dragon_152: [(v) => !!v || "Total Dragon 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 15s greater than 0"],
          total_bet_win_a_day_dragon_153: [(v) => !!v || "Total Dragon 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 15s greater than 0"],
          total_bet_win_a_day_dragon_154: [(v) => !!v || "Total Dragon 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Dragon 15s greater than 0"],
          total_bet_win_a_day_baccarat_601: [(v) => !!v || "Total Baccarat 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 60s greater than 0"],
          total_bet_win_a_day_baccarat_602: [(v) => !!v || "Total Baccarat 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 60s greater than 0"],
          total_bet_win_a_day_baccarat_251: [(v) => !!v || "Total Baccarat 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 25s greater than 0"],
          total_bet_win_a_day_baccarat_252: [(v) => !!v || "Total Baccarat 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 25s greater than 0"],
          total_bet_win_a_day_baccarat_253: [(v) => !!v || "Total Baccarat 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 25s greater than 0"],
          total_bet_win_a_day_baccarat_254: [(v) => !!v || "Total Baccarat 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 25s greater than 0"],
          total_bet_win_a_day_baccarat_151: [(v) => !!v || "Total Baccarat 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 15s greater than 0"],
          total_bet_win_a_day_baccarat_152: [(v) => !!v || "Total Baccarat 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 15s greater than 0"],
          total_bet_win_a_day_baccarat_153: [(v) => !!v || "Total Baccarat 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 15s greater than 0"],
          total_bet_win_a_day_baccarat_154: [(v) => !!v || "Total Baccarat 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Baccarat 15s greater than 0"],
          total_bet_win_a_day_doden_60: [(v) => !!v || "Total Doden 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Doden 60s greater than 0"],
          total_bet_win_a_day_doden_251: [(v) => !!v || "Total Doden 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Doden 25s greater than 0"],
          total_bet_win_a_day_doden_252: [(v) => !!v || "Total Doden 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Doden 25s greater than 0"],
          total_bet_win_a_day_doden_151: [(v) => !!v || "Total Doden 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Doden 15s greater than 0"],
              total_bet_win_a_day_doden_152: [(v) => !!v || "Total Doden 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Doden 15s greater than 0"],
          total_bet_win_a_day_taixiu_60: [(v) => !!v || "Total Taixiu 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Taixiu 60s greater than 0"],
          total_bet_win_a_day_taixiu_251: [(v) => !!v || "Total Taixiu 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Taixiu 25s greater than 0"],
          total_bet_win_a_day_taixiu_252: [(v) => !!v || "Total Taixiu 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Taixiu 25s greater than 0"],
          total_bet_win_a_day_taixiu_151: [(v) => !!v || "Total Taixiu 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Taixiu 15s greater than 0"],
          total_bet_win_a_day_taixiu_152: [(v) => !!v || "Total Taixiu 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Taixiu 15s greater than 0"],
          total_bet_win_a_day_sicbo_60: [(v) => !!v || "Total Sicbo 60s is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo 60s greater than 0"],
          total_bet_win_a_day_sicbo_251: [(v) => !!v || "Total Sicbo 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo 25s greater than 0"],
          total_bet_win_a_day_sicbo_252: [(v) => !!v || "Total Sicbo 25s is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo 25s greater than 0"],
          total_bet_win_a_day_sicbo_151: [(v) => !!v || "Total Sicbo 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo 15s greater than 0"],
          total_bet_win_a_day_sicbo_152: [(v) => !!v || "Total Sicbo 15s is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo 15s greater than 0"],
          total_bet_win_a_day_keno_160: [(v) => !!v || "Total Keno is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
          total_bet_win_a_day_keno_100: [(v) => !!v || "Total Keno is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
          total_bet_win_a_day_keno_30: [(v) => !!v || "Total Keno is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
          total_bet_win_a_day_lottery3d_160: [(v) => !!v || "Total Lottery3D is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
          total_bet_win_a_day_lottery3d_100: [(v) => !!v || "Total Lottery3D is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
          total_bet_win_a_day_lottery3d_30: [(v) => !!v || "Total Lottery3D is required",
          (v) => (!!v && v > 0) ||
              "Total Sicbo greater than 0"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig:[],
    dataConfig1: {
      type: Object,
      default: () => {
        return {
          // total_bet_win_a_day_lotto: null,
          // total_bet_win_a_day_yuki: null,
          // total_bet_win_a_day_pp78: null,
          // total_bet_win_a_day_klaklouk: null,
          // total_bet_win_a_day_dragon: null,
          // total_bet_win_a_day_baccarat: null,
          // total_bet_win_a_day_doden: null,
          // total_bet_win_a_day_taixiu: null,
          // total_bet_win_a_day_sicbo: null,
          // enable_lotto:true,
          // enable_yuki:true,
          // enable_pp78:true,
          // enable_klaklouk:true,
          // enable_dragon:true,
          // enable_baccarat:true,
          // enable_doden:true,
          // enable_taixiu:true,
          // enable_sicbo:true,
        };
      },
    },
  },
  data() {
    return {
      tab:null,
    };
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  methods: {
    validate(name) {
      if (this.$refs.form.validate()) {
        this.$emit("submit",name);
      }
    },

    remove(item) {
      this.bankUser.permissions.splice(this.bankUser.permissions.indexOf(item), 1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
