<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="80%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">Table Master</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>

        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input
              v-model="dataRequest.search"
              @input="$emit('getData2')"
              type="text"
              class="form-control pwstrength"
            />
          </div>
          <br />
          <v-data-table
            style="font-family: 'khmer mef1'"
            class=" table table-striped"
            item-key="_id"
            :headers="headers"
            :footer-props="footerProps"
            :items="users ? users.object : []"
            :server-items-length="users ? users.objectCount.count : null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="$emit('getData2')"
            @update:items-per-page="$emit('getData2')"
            @update:sort-desc="$emit('getData2')"
            mobile-breakpoint="0"
          >
            <!--inactive active -->
            <template v-slot:[`item.enable`]="{ item }">
              <span>
                  <a
                    style="font-weight: 400"
                    :class="
                      item.enable ? 'text-green' : 'text-red'
                    "
                    @click="_openDialogEnable(item)"
                    :disabled="userp && !userp.enable ? true : false"
                  >
                    {{ item.enable ? "Enable(Betting)" : "Not Enable(Betting)" }}
                  </a>
                </span>
                <span>
                  <a
                    style="font-weight: 400"
                    :class="
                      item.is_suspend ? 'text-red' : 'text-green'
                    "
                    @click="_openDialogSuspend(item)"
                    :disabled="userp && !userp.is_suspend ? true : false"
                  >
                    {{ item.is_suspend ? "| Suspend(Login)" : "| Active(Login)" }}
                  </a>
                </span>
            </template>

            <template v-slot:[`item.balance`]="{ item }">
              <span v-if="item && (item.role_id === '61829dbbe64b1b17688326cb')">
                <span v-for="ms in item.master_balance" :key="ms.id">
                  <div v-if="ms.currency_type == 1">{{ currencyFormatUsd(ms.balance) }} $</div>
                  <div v-else-if="ms.currency_type == 2">{{ currencyFormatKh(ms.balance) }} ៛</div>
                  <div v-else-if="ms.currency_type == 4">{{ currencyFormatKh(ms.balance) }} đ</div>
                  <div v-else-if="ms.currency_type == 5">{{ currencyFormatKh(ms.balance) }} ฿</div>
                </span>
              </span>
              <span v-else>
                {{ currencyFormatKh(item.balance) }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="green"
                small
                @click="getUserChild3(item)"
                style="background:  none !important;"
                v-if="item.objChild > 0"
                >mdi-eye
              </v-icon>
              <v-icon style="background:  none !important;" color="green" small disabled v-else
                >mdi-eye-off
              </v-icon>
            </template>

            <!--limit betting-->
            <template v-slot:[`item.updateConfigUser`]="{ item }">
              <v-menu offset-x left :close-on-content-click="false" v-if="checkUserPermission('EDIT')
                && (userp.role_id == '611532b19c6e77ad0fd50c1c' || userp.role_id == '612a3fcc1a1fcead8871e822' || userp.role_id == '61829d84e64b1b17688326a3') ">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text dark v-bind="attrs" v-on="on" class="btn btn-info btn-action mr-1" style="min-width:0px" 
                    @click="updateLimit(item)"
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <i class="fas fa-cogs"></i>
                          </span>
                      </template>
                      <span>Limmit Betting</span>
                    </v-tooltip>
                    
                  </v-btn>
                  </template>
                  <v-list>
                    <v-list-group
                    :value="false">
                  <template v-slot:activator>
                    <v-list-item-title>Limit USD</v-list-item-title>
                  </template>
                  <v-list-item
                      v-for="t in getListMenuByApp"
                      :key="t.title+'0'"
                      @click="updateCompanyLimitBetting(1,t.channels,item)"
                    >
                      <v-list-item-title class="ml-5" v-text="t.title"></v-list-item-title>
                    </v-list-item>
                    </v-list-group>
                    <v-list-group
                      :value="false">
                      <template v-slot:activator>
                        <v-list-item-title>Limit KH</v-list-item-title>
                      </template>
                      <v-list-item
                          v-for="t in getListMenuByApp"
                          :key="t.title+'1'"
                          @click="updateCompanyLimitBetting(2,t.channels,item)"
                        >
                          <v-list-item-title class="ml-5" v-text="t.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-group
                      :value="false">
                      <template v-slot:activator>
                        <v-list-item-title>Limit VN</v-list-item-title>
                      </template>
                      <v-list-item
                          v-for="t in getListMenuByApp"
                          :key="t.title+'2'"
                          @click="updateCompanyLimitBetting(4,t.channels,item)"
                        >
                          <v-list-item-title class="ml-5" v-text="t.title"></v-list-item-title>
                        </v-list-item>
                      </v-list-group>
                      <v-list-group
                        :value="false">
                        <template v-slot:activator>
                          <v-list-item-title>Limit RP</v-list-item-title>
                        </template>
                        <v-list-item
                          v-for="t in getListMenuByApp"
                          :key="t.title+'3'"
                          @click="updateCompanyLimitBetting(5,t.channels,item)">
                          <v-list-item-title class="ml-5" v-text="t.title"></v-list-item-title>
                        </v-list-item>
                      </v-list-group>
                  </v-list>
                </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <user-list3
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild3"
      :data-request="data3"
      :userp="userp"
      v-on:getData3="getUserChild3(item)"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />

    <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <div class="card">
        <div class="card-header">
          <h4>Active(Login)/Suspend(Login)</h4>
        </div>
        <div class="card-body">
          This will effect this user and its children. Do you want to
          confirm?
        </div>
        <div class="card-footer text-right">
          <button
            class="btn btn-light mr-1"
            @click="_closeDialogSuspend"
          >
            Close
          </button>
          <button
            class="btn"
            :class="
              selectedUser && selectedUser.is_suspend
                ? 'btn-success'
                : 'btn-danger'
            "
            @click="_updateUserSuspendStatus()"
          >
            {{
              selectedUser && selectedUser.is_suspend
                ? "Active"
                : "Suspend"
            }}
          </button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="enableDialog" persistent max-width="500px">
      <div class="card">
        <div class="card-header">
          <h4>Enable(Betting)/Not Enable(Betting)</h4>
        </div>
        <div class="card-body">
          This will effect this user and its children. Do you want to
          confirm?
        </div>
        <div class="card-footer text-right">
          <button
            class="btn btn-light mr-1"
            @click="_closeDialogEnable"
          >
            Close
          </button>
          <button
            class="btn"
            :class="
              selectedUser && selectedUser.enable
                ? 'btn-danger'
                : 'btn-success'
            "
            @click="_updateUserEnableStatus()"
          >
            {{
              selectedUser && selectedUser.enable
                ? "Not Enable"
                : "Enable"
            }}
          </button>
        </div>
      </div>
    </v-dialog>
    <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    
    <config-high-level-user-company-usd
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyUsd"
      :dataConfig="configHighLevelUserCompanyUsd"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyUsd"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyUsdDialog"
    />
    <config-high-level-user-company-kh
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyKh"
      :dataConfig="configHighLevelUserCompanyKh"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyKh"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyKhDialog"
    />
    <config-high-level-user-company-vn
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyVn"
      :dataConfig="configHighLevelUserCompanyVn"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyVn"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyVnDialog"
    />
    <config-high-level-user-company-rp
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyRp"
      :dataConfig="configHighLevelUserCompanyRp"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyRp"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyRpDialog"
    />

  </div>
</template>

<script>
import UserList3 from "./Dialog3";
import { mapActions,mapGetters } from "vuex";
import ConfigHighLevelUserCompanyUsd from "../ConfigHighLevelUserCompanyUsd";
import ConfigHighLevelUserCompanyKh from "../ConfigHighLevelUserCompanyKh";
import ConfigHighLevelUserCompanyVn from "../ConfigHighLevelUserCompanyVn";
import ConfigHighLevelUserCompanyRp from "../ConfigHighLevelUserCompanyRp";

export default {
  components: { UserList3,
    ConfigHighLevelUserCompanyUsd,
    ConfigHighLevelUserCompanyKh,
    ConfigHighLevelUserCompanyVn,
    ConfigHighLevelUserCompanyRp
  },
  props: ["darkmode", "dialog", "users", "dataRequest", "userp"],
  data: () => {
    return {
      dialogListUser: false,
      userChild3: [],
      footerProps: {
        "items-per-page-options": [1, 20, 50, 100, 200],
      },
      item: null,
      data3: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null,
      },
      selectedUser: [],
      suspendDialog: false,
      enableDialog: false,
      waiting: false,
      limitConfig: {},
      loading: false,
      channel_type: 1,
      configHighLevelUserCompanyUsd: [],
      configHighLevelUserCompanyKh: [],
      configHighLevelUserCompanyVn: [],
      configHighLevelUserCompanyRp: [],
      dialogConfigHighLevelUserCompanyUsd: false,
      dialogConfigHighLevelUserCompanyKh: false,
      dialogConfigHighLevelUserCompanyVn: false,
      dialogConfigHighLevelUserCompanyRp: false,
    };
  },
  computed: {
    headers() {
      return [
      { text: "Status", value: "enable", align: "center" },
        {
          text: this.$t("header.user_name"),
          align: "center",
          sortable: false,
          value: "user_name",
        },
        {
          text: this.$t("header.bank_account"),
          value: "account_info",
          align: "center",
        },
        { text: this.$t("header.balance"), value: "balance", align: "center" },
        { text: this.$t("header.role"), value: "role_name", align: "center" },
        {
          text: this.$t("header.created_at"),
          value: "updatedAt",
          align: "center",
        },
        {
          text: this.$t("header.updateConfig"),
          value: "updateConfigUser",
          align: "center",
        },
        
        {
          text: this.$t("header.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
    ...mapGetters("$_user", ["getListMenuByApp"]),
  },
  methods: {
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    async updateLimit(item) {
      console.log(item);
      await this.fetchListMenuByApp(item);
    },
    getUserChild3(item) {
      this.item = item;
      this.data3.parentId = this.item._id;
      this.data3.sortByFormat.length < 1
        ? (this.data3.sortBy = "_id")
        : (this.data3.sortBy = this.data3.sortByFormat[0]);
      this.data3.descendingFormat.length > 0
        ? (this.data3.descending = this.data3.descendingFormat[0])
        : (this.data3.descending = true);
      this.$request
        .post({
          url: "users/getAllByUserId",
          data: this.data3,
        })
        .then((res) => {
          if (res.data.code) {
            this.userChild3 = res.data.data;
            this.dialogListUser = true;
          }
        });
    },
    _openDialogSuspend(item) {
      this.selectedUser = item;
      this.suspendDialog = true;
    },
    _openDialogEnable(item) {
      this.selectedUser = item;
      this.enableDialog = true;
    },
    _closeDialogSuspend() {
      this.selectedUser = [];
      this.suspendDialog = false;
    },
    _closeDialogEnable() {
      this.selectedUser = [];
      this.enableDialog = false;
    },
    async _updateUserEnableStatus() {
      this.waiting = true;
      let data = {
        enable: !this.selectedUser.enable,
        userId: this.selectedUser._id, 
        // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/enableUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.$emit("getData2");
            this._closeDialogEnable();
            this.waiting = false;
          } else {
            this.$emit("getData2");
            this._closeDialogEnable();
            this.waiting = false;
          }
        });
    },
    async _updateUserSuspendStatus() {
      this.waiting = true;
      let data = {
        is_suspend: !this.selectedUser.is_suspend,
        userId: this.selectedUser._id, // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.$emit("getData2");
            this._closeDialogSuspend();
            this.waiting = false;
          } else {
            this.$emit("getData2");
            this._closeDialogSuspend();
            this.waiting = false;
          }
        });
    },

    async onChangeTab(channel_type){
      await this.getLimitData(this.item,channel_type);
    },
    async getLimitData(item, channel_type) {
      let data = {
        currency_type: item.currency_type,
        _id: item.parent_id,
        channel_type: channel_type
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBettingByChannel",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },

    updateCompanyLimitBetting(value,channels,user){
      if(value == 1){
        this.updateCompanyLimitBettingUsd(channels,user);
      }else if(value == 2){
        this.updateCompanyLimitBettingKh(channels,user);
      }else if(value == 4){
        this.updateCompanyLimitBettingVn(channels,user);
      }else if(value == 5){
        this.updateCompanyLimitBettingRp(channels,user);
      }
    },
    updateCompanyLimitBettingUsd(channels,user) {

      let data = { user_id: user._id,channels:channels };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyUsd = res.data.data;
          
          this.dialogConfigHighLevelUserCompanyUsd = true;
        });
    },
    updateCompanyLimitBettingKh(channels,user) {
      let data = { user_id: user._id,channels:channels};
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyKh = res.data.data;
          this.dialogConfigHighLevelUserCompanyKh = true;
        });
    },
    updateCompanyLimitBettingVn(channels,user) {
      let data = { user_id: user._id,channels:channels };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyVn = res.data.data;
          this.dialogConfigHighLevelUserCompanyVn = true;
        });
    },
    updateCompanyLimitBettingRp(channels,user) {
      let data = { user_id: user._id,channels:channels};
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyRp = res.data.data;
          this.dialogConfigHighLevelUserCompanyRp = true;
        });
    },
    closeConfigHighLevelUserCompanyUsdDialog() {
      this.dialogConfigHighLevelUserCompanyUsd = false;
    },
    closeConfigHighLevelUserCompanyKhDialog() {
      this.dialogConfigHighLevelUserCompanyKh = false;
    },
    closeConfigHighLevelUserCompanyVnDialog() {
      this.dialogConfigHighLevelUserCompanyVn = false;
    },
    closeConfigHighLevelUserCompanyRpDialog() {
      this.dialogConfigHighLevelUserCompanyRp = false;
    },
    async onChangeConfigHighLevelUserCompanyUsd(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyUsd.objectUsd.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyUsd.objectUsd;
      }
      // លុយដូល្លា
      data.currency_type = 1;
      if (this.configHighLevelUserCompanyUsd.objectUsd[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyKh(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyKh.objectKh.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyKh.objectKh;
      }
      
      // លុយ ខ្មែរ
      data.currency_type = 2;
      if (this.configHighLevelUserCompanyKh.objectKh[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyVn(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyVn.objectVn.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyVn.objectVn;
      }
      
      // លុយ វៀតណាម
      data.currency_type = 4;
      if (this.configHighLevelUserCompanyVn.objectVn[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyRp(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyRp.objectRp.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyRp.objectRp;
      }
      
      // លុយ វៀតណាម
      data.currency_type = 5;
      if (this.configHighLevelUserCompanyRp.objectRp[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    ...mapActions("$_configUser", ["updateLimitSetting"]),
    ...mapActions("$_user", ["updateConfigUser","fetchListMenuByApp"]),
  },
};
</script>

<style scoped>
</style>
