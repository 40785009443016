var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialog && _vm.dataConfig)?_c('div',[_c('v-dialog',{attrs:{"value":_vm.dialog,"persistent":"","max-width":"50%","transition":"dialog-bottom-transition"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(!_vm.dataConfig._id ? _vm.$t("title.configSlotLimit") : _vm.$t("title.configSlotLimit")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-tabs',{attrs:{"background-color":"blue-grey lighten-5 accent-4","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.dataConfig),function(channel,channelId){return _c('v-tab',{key:channelId},[_vm._v(_vm._s(channel.name))])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.dataConfig),function(object,channelId2){return _c('v-tab-item',{key:channelId2},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"form-group"},_vm._l((object.sub_menus),function(indexData,index){return _c('v-row',{key:index},[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"5","sm":"5","md":"5"}},[_c('v-checkbox',{staticStyle:{"padding-left":"12px"},attrs:{"label":indexData.channel_type ==90 ? 'ដាក់ដំណើរការ(Lotto 160s)' 
                                :indexData.channel_type ==901 ? 'ដាក់ដំណើរការ(Lotto 100s)'
                                :indexData.channel_type ==902 ? 'ដាក់ដំណើរការ(Lotto 30s)'
                                :indexData.channel_type ==91 ? 'ដាក់ដំណើរការ(Yuki 160s)'
                                :indexData.channel_type ==911 ? 'ដាក់ដំណើរការ(Yuki 100s)'
                                :indexData.channel_type ==912 ? 'ដាក់ដំណើរការ(Yuki 30s)'
                                :indexData.channel_type ==92 ? 'ដាក់ដំណើរការ(LOTTERY 160s)'
                                :indexData.channel_type ==921 ? 'ដាក់ដំណើរការ(LOTTERY 100s)'
                                :indexData.channel_type ==922 ? 'ដាក់ដំណើរការ(LOTTERY 30s)'
                                :indexData.channel_type ==89 ? 'ដាក់ដំណើរការ(LOTTERY3D 160s)'
                                :indexData.channel_type ==891 ? 'ដាក់ដំណើរការ(LOTTERY3D 100s)'
                                :indexData.channel_type ==892 ? 'ដាក់ដំណើរការ(LOTTERY3D 30s)'
                                :indexData.channel_type ==99 ? 'ដាក់ដំណើរការ(KENO 160s)'
                                :indexData.channel_type ==991 ? 'ដាក់ដំណើរការ(KENO 100s)'
                                :indexData.channel_type ==992 ? 'ដាក់ដំណើរការ(KENO 30s)'
                                :indexData.channel_type ==93 ? 'ដាក់ដំណើរការ(KLAKLOUK 60s)'
                                :indexData.channel_type ==931 ? 'ដាក់ដំណើរការ(KLAKLOUK 25s)'
                                :indexData.channel_type ==932 ? 'ដាក់ដំណើរការ(KLAKLOUK 25s)'
                                :indexData.channel_type ==933 ? 'ដាក់ដំណើរការ(KLAKLOUK 15s)'
                                :indexData.channel_type ==934 ? 'ដាក់ដំណើរការ(KLAKLOUK 15s)'
                                :indexData.channel_type ==94 ? 'ដាក់ដំណើរការ(DRAGON 60s)'
                                :indexData.channel_type ==941 ? 'ដាក់ដំណើរការ(DRAGON 60s)'
                                :indexData.channel_type ==942 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                :indexData.channel_type ==943 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                :indexData.channel_type ==944 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                :indexData.channel_type ==945 ? 'ដាក់ដំណើរការ(DRAGON 25s)'
                                :indexData.channel_type ==946 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                :indexData.channel_type ==947 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                :indexData.channel_type ==948 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                :indexData.channel_type ==949 ? 'ដាក់ដំណើរការ(DRAGON 15s)'
                                :indexData.channel_type ==95 ? 'ដាក់ដំណើរការ(BACCARAT 60s)'
                                :indexData.channel_type ==951 ? 'ដាក់ដំណើរការ(BACCARAT 60s)'
                                :indexData.channel_type ==952 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                :indexData.channel_type ==953 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                :indexData.channel_type ==954 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                :indexData.channel_type ==955 ? 'ដាក់ដំណើរការ(BACCARAT 25s)'
                                :indexData.channel_type ==956 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                :indexData.channel_type ==957 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                :indexData.channel_type ==958 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                :indexData.channel_type ==959 ? 'ដាក់ដំណើរការ(BACCARAT 15s)'
                                :indexData.channel_type ==96 ? 'ដាក់ដំណើរការ(DODEN 60s)'
                                :indexData.channel_type ==961 ? 'ដាក់ដំណើរការ(DODEN 25s)'
                                :indexData.channel_type ==962 ? 'ដាក់ដំណើរការ(DODEN 25s)'
                                :indexData.channel_type ==963 ? 'ដាក់ដំណើរការ(DODEN 15s)'
                                :indexData.channel_type ==964 ? 'ដាក់ដំណើរការ(DODEN 15s)'
                                :indexData.channel_type ==97 ? 'ដាក់ដំណើរការ(SICBO 60s)'
                                :indexData.channel_type ==971 ? 'ដាក់ដំណើរការ(SICBO 25s)'
                                :indexData.channel_type ==972 ? 'ដាក់ដំណើរការ(SICBO 25s)'
                                :indexData.channel_type ==973 ? 'ដាក់ដំណើរការ(SICBO 15s)'
                                :indexData.channel_type ==974 ? 'ដាក់ដំណើរការ(SICBO 15s)'
                                :indexData.channel_type ==98 ? 'ដាក់ដំណើរការ(TAIXIU 60s)'
                                :indexData.channel_type ==981 ? 'ដាក់ដំណើរការ(TAIXIU 25s)'
                                :indexData.channel_type ==982 ? 'ដាក់ដំណើរការ(TAIXIU 25s)'
                                :indexData.channel_type ==983 ? 'ដាក់ដំណើរការ(TAIXIU 15s)'
                                :indexData.channel_type ==984 ? 'ដាក់ដំណើរការ(TAIXIU 15s)'
                                :''},model:{value:(indexData.enable),callback:function ($$v) {_vm.$set(indexData, "enable", $$v)},expression:"indexData.enable"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"rules":indexData.channel_type ==90 ? _vm.rules.total_bet_win_a_day_lotto_160
                                :indexData.channel_type ==901 ? _vm.rules.total_bet_win_a_day_lotto_100
                                :indexData.channel_type ==902 ? _vm.rules.total_bet_win_a_day_lotto_30
                                :indexData.channel_type ==91 ? _vm.rules.total_bet_win_a_day_yuki_160
                                :indexData.channel_type ==911 ? _vm.rules.total_bet_win_a_day_yuki_100
                                :indexData.channel_type ==912 ? _vm.rules.total_bet_win_a_day_yuki_30
                                :indexData.channel_type ==92 ? _vm.rules.total_bet_win_a_day_pp78_160
                                :indexData.channel_type ==921 ? _vm.rules.total_bet_win_a_day_pp78_100
                                :indexData.channel_type ==922 ? _vm.rules.total_bet_win_a_day_pp78_30
                                :indexData.channel_type ==93 ? _vm.rules.total_bet_win_a_day_klaklouk_60
                                :indexData.channel_type ==931 ? _vm.rules.total_bet_win_a_day_klaklouk_251
                                :indexData.channel_type ==932 ? _vm.rules.total_bet_win_a_day_klaklouk_252
                                :indexData.channel_type ==933 ? _vm.rules.total_bet_win_a_day_klaklouk_151
                                :indexData.channel_type ==934 ? _vm.rules.total_bet_win_a_day_klaklouk_152
                                :indexData.channel_type ==94 ? _vm.rules.total_bet_win_a_day_dragon_601
                                :indexData.channel_type ==941 ? _vm.rules.total_bet_win_a_day_dragon_602
                                :indexData.channel_type ==942 ? _vm.rules.total_bet_win_a_day_dragon_251
                                :indexData.channel_type ==943 ? _vm.rules.total_bet_win_a_day_dragon_252
                                :indexData.channel_type ==944 ? _vm.rules.total_bet_win_a_day_dragon_253
                                :indexData.channel_type ==945 ? _vm.rules.total_bet_win_a_day_dragon_254
                                :indexData.channel_type ==946 ? _vm.rules.total_bet_win_a_day_dragon_151
                                :indexData.channel_type ==947 ? _vm.rules.total_bet_win_a_day_dragon_152
                                :indexData.channel_type ==948 ? _vm.rules.total_bet_win_a_day_dragon_153
                                :indexData.channel_type ==949 ? _vm.rules.total_bet_win_a_day_dragon_154
                                :indexData.channel_type ==95 ? _vm.rules.total_bet_win_a_day_baccarat_601
                                :indexData.channel_type ==951 ? _vm.rules.total_bet_win_a_day_baccarat_602
                                :indexData.channel_type ==952 ? _vm.rules.total_bet_win_a_day_baccarat_251
                                :indexData.channel_type ==953 ? _vm.rules.total_bet_win_a_day_baccarat_252
                                :indexData.channel_type ==954 ? _vm.rules.total_bet_win_a_day_baccarat_253
                                :indexData.channel_type ==955 ? _vm.rules.total_bet_win_a_day_baccarat_254
                                :indexData.channel_type ==956 ? _vm.rules.total_bet_win_a_day_baccarat_151
                                :indexData.channel_type ==957 ? _vm.rules.total_bet_win_a_day_baccarat_152
                                :indexData.channel_type ==958 ? _vm.rules.total_bet_win_a_day_baccarat_153
                                :indexData.channel_type ==959 ? _vm.rules.total_bet_win_a_day_baccarat_154
                                :indexData.channel_type ==96 ? _vm.rules.total_bet_win_a_day_doden_60
                                :indexData.channel_type ==961 ? _vm.rules.total_bet_win_a_day_doden_251
                                :indexData.channel_type ==962 ? _vm.rules.total_bet_win_a_day_doden_252
                                :indexData.channel_type ==963 ? _vm.rules.total_bet_win_a_day_doden_151
                                :indexData.channel_type ==964 ? _vm.rules.total_bet_win_a_day_doden_152
                                :indexData.channel_type ==97 ? _vm.rules.total_bet_win_a_day_sicbo_60
                                :indexData.channel_type ==971 ? _vm.rules.total_bet_win_a_day_sicbo_251
                                :indexData.channel_type ==972 ? _vm.rules.total_bet_win_a_day_sicbo_252
                                :indexData.channel_type ==973 ? _vm.rules.total_bet_win_a_day_sicbo_151
                                :indexData.channel_type ==974 ? _vm.rules.total_bet_win_a_day_sicbo_152
                                :indexData.channel_type ==98 ? _vm.rules.total_bet_win_a_day_taixiu_60
                                :indexData.channel_type ==981 ? _vm.rules.total_bet_win_a_day_taixiu_251
                                :indexData.channel_type ==982 ? _vm.rules.total_bet_win_a_day_taixiu_252
                                :indexData.channel_type ==983 ? _vm.rules.total_bet_win_a_day_taixiu_151
                                :indexData.channel_type ==984 ? _vm.rules.total_bet_win_a_day_taixiu_152
                                :indexData.channel_type ==99 ? _vm.rules.total_bet_win_a_day_keno_160
                                :indexData.channel_type ==991 ? _vm.rules.total_bet_win_a_day_keno_100
                                :indexData.channel_type ==992 ? _vm.rules.total_bet_win_a_day_keno_30
                                :indexData.channel_type ==89 ? _vm.rules.total_bet_win_a_day_lottery3d_160
                                :indexData.channel_type ==891 ? _vm.rules.total_bet_win_a_day_lottery3d_100
                                :indexData.channel_type ==892 ? _vm.rules.total_bet_win_a_day_lottery3d_30
                                :'',"type":"number","label":indexData.channel_type ==90 ? _vm.$t('input.total_bet_win_a_day_lotto160') 
                                :indexData.channel_type ==901 ? _vm.$t('input.total_bet_win_a_day_lotto100') 
                                :indexData.channel_type ==902 ? _vm.$t('input.total_bet_win_a_day_lotto30') 
                                :indexData.channel_type ==91 ? _vm.$t('input.total_bet_win_a_day_yuki160')
                                :indexData.channel_type ==911 ? _vm.$t('input.total_bet_win_a_day_yuki100')
                                :indexData.channel_type ==912 ? _vm.$t('input.total_bet_win_a_day_yuki30')
                                :indexData.channel_type ==92 ? _vm.$t('input.total_bet_win_a_day_pp78160')
                                :indexData.channel_type ==921 ? _vm.$t('input.total_bet_win_a_day_pp78100')
                                :indexData.channel_type ==922 ? _vm.$t('input.total_bet_win_a_day_pp7830')
                                :indexData.channel_type ==93 ? _vm.$t('input.total_bet_win_a_day_klaklouk60')
                                :indexData.channel_type ==931 ? _vm.$t('input.total_bet_win_a_day_klaklouk25')
                                :indexData.channel_type ==932 ? _vm.$t('input.total_bet_win_a_day_klaklouk25')
                                :indexData.channel_type ==933 ? _vm.$t('input.total_bet_win_a_day_klaklouk15')
                                :indexData.channel_type ==934 ? _vm.$t('input.total_bet_win_a_day_klaklouk15')
                                :indexData.channel_type ==94 ? _vm.$t('input.total_bet_win_a_day_dragon60')
                                :indexData.channel_type ==941 ? _vm.$t('input.total_bet_win_a_day_dragon60')
                                :indexData.channel_type ==942 ? _vm.$t('input.total_bet_win_a_day_dragon25')
                                :indexData.channel_type ==943 ? _vm.$t('input.total_bet_win_a_day_dragon25')
                                :indexData.channel_type ==944 ? _vm.$t('input.total_bet_win_a_day_dragon25')
                                :indexData.channel_type ==945 ? _vm.$t('input.total_bet_win_a_day_dragon25')
                                :indexData.channel_type ==946 ? _vm.$t('input.total_bet_win_a_day_dragon15')
                                :indexData.channel_type ==947 ? _vm.$t('input.total_bet_win_a_day_dragon15')
                                :indexData.channel_type ==948 ? _vm.$t('input.total_bet_win_a_day_dragon15')
                                :indexData.channel_type ==949 ? _vm.$t('input.total_bet_win_a_day_dragon15')
                                :indexData.channel_type ==95 ? _vm.$t('input.total_bet_win_a_day_baccarat60')
                                :indexData.channel_type ==951 ? _vm.$t('input.total_bet_win_a_day_baccarat60')
                                :indexData.channel_type ==952 ? _vm.$t('input.total_bet_win_a_day_baccarat25')
                                :indexData.channel_type ==953 ? _vm.$t('input.total_bet_win_a_day_baccarat25')
                                :indexData.channel_type ==954 ? _vm.$t('input.total_bet_win_a_day_baccarat25')
                                :indexData.channel_type ==955 ? _vm.$t('input.total_bet_win_a_day_baccarat25')
                                :indexData.channel_type ==956 ? _vm.$t('input.total_bet_win_a_day_baccarat15')
                                :indexData.channel_type ==957 ? _vm.$t('input.total_bet_win_a_day_baccarat15')
                                :indexData.channel_type ==958 ? _vm.$t('input.total_bet_win_a_day_baccarat15')
                                :indexData.channel_type ==959 ? _vm.$t('input.total_bet_win_a_day_baccarat15')
                                :indexData.channel_type ==96 ? _vm.$t('input.total_bet_win_a_day_doden60')
                                :indexData.channel_type ==961 ? _vm.$t('input.total_bet_win_a_day_doden25')
                                :indexData.channel_type ==962 ? _vm.$t('input.total_bet_win_a_day_doden25')
                                :indexData.channel_type ==963 ? _vm.$t('input.total_bet_win_a_day_doden15')
                                :indexData.channel_type ==964 ? _vm.$t('input.total_bet_win_a_day_doden15')
                                :indexData.channel_type ==97 ? _vm.$t('input.total_bet_win_a_day_sicbo60')
                                :indexData.channel_type ==971 ? _vm.$t('input.total_bet_win_a_day_sicbo25')
                                :indexData.channel_type ==972 ? _vm.$t('input.total_bet_win_a_day_sicbo25')
                                :indexData.channel_type ==973 ? _vm.$t('input.total_bet_win_a_day_sicbo15')
                                :indexData.channel_type ==974 ? _vm.$t('input.total_bet_win_a_day_sicbo15')
                                :indexData.channel_type ==98 ? _vm.$t('input.total_bet_win_a_day_taixiu60')
                                :indexData.channel_type ==981 ? _vm.$t('input.total_bet_win_a_day_taixiu25')
                                :indexData.channel_type ==982 ? _vm.$t('input.total_bet_win_a_day_taixiu25')
                                :indexData.channel_type ==983 ? _vm.$t('input.total_bet_win_a_day_taixiu15')
                                :indexData.channel_type ==984 ? _vm.$t('input.total_bet_win_a_day_taixiu15')
                                :indexData.channel_type ==99 ? _vm.$t('input.total_bet_win_a_day_keno160')
                                :indexData.channel_type ==991 ? _vm.$t('input.total_bet_win_a_day_keno100')
                                :indexData.channel_type ==992 ? _vm.$t('input.total_bet_win_a_day_keno30')
                                :indexData.channel_type ==89 ? _vm.$t('input.total_bet_win_a_day_lot3160')
                                :indexData.channel_type ==891 ? _vm.$t('input.total_bet_win_a_day_lot3100')
                                :indexData.channel_type ==892 ? _vm.$t('input.total_bet_win_a_day_lot330')
                                :'',"hide-details":"auto"},model:{value:(indexData.total_bet_win_a_day),callback:function ($$v) {_vm.$set(indexData, "total_bet_win_a_day", $$v)},expression:"indexData.total_bet_win_a_day"}})],1)],1)}),1),_c('div',{staticClass:"col-12",staticStyle:{"text-align":"end"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.validate(object.name)}}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)])],1)}),1)],1)],1)],1)],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }