<template>
  <div v-if="dialog && users">
    <v-dialog :value="dialog" max-width="80%" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.user_list') }} 4
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon  @click="$emit('onCloseDialog')">
            <i class="fas fa-lg fa-window-close"></i>
          </v-btn> -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </v-toolbar>
        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input
              v-model="dataRequest.search"
              @input="$emit('getData4')"
              type="text"
              class="form-control pwstrength"
            />
          </div>
          <br />
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              :headers="headers"
              :footer-props="footerProps"
              :items="users ? users.object : []"
              :server-items-length="users ? users.objectCount.count : 0"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="$emit('getData4')"
              @update:items-per-page="$emit('getData4')"
              @update:sort-desc="$emit('getData4')"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item.enable`]="{ item }">
              <span>
                  <a
                    style="font-weight: 400"
                    :class="
                      item.enable ? 'text-green' : 'text-red'
                    "
                    @click="_openDialogEnable(item)"
                    :disabled="userp && !userp.enable ? true : false"
                  >
                    {{ item.enable ? "Enable(Betting)" : "Not Enable(Betting)" }}
                  </a>
                </span>
                <span>
                  <a
                    style="font-weight: 400"
                    :class="
                      item.is_suspend ? 'text-red' : 'text-green'
                    "
                    @click="_openDialogSuspend(item)"
                    :disabled="userp && !userp.is_suspend ? true : false"
                  >
                    {{ item.is_suspend ? "| Suspend(Login)" : "| Active(Login)" }}
                  </a>
                </span>
            </template>

            <template v-slot:[`item.balance`]="{ item }">
                  <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                  {{ currencyFormatUsd(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===4" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
              
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green"
                      small class="mr-2"
                      @click="getUserChild5(item)"
                      style="background:  none !important;"
                      v-if="item.objChild > 0"
              >mdi-eye
              </v-icon>
              <v-icon color="green"
                      small class="mr-2"
                      disabled
                      style="background:  none !important;"
                      v-else
              >mdi-eye-off
              </v-icon>
            </template>
            <template v-slot:[`item.updateConfigUser`]="{ item }">
              <v-menu offset-y class="mx-0 px-0 "
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text dark v-bind="attrs" v-on="on" class="btn btn-success btn-action mr-1" style="min-width:0px" 
                  @click="updateLimit(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <i class="fas fa-cogs"></i>
                          </span>
                      </template>
                      <span>Limmit Betting</span>
                    </v-tooltip>
                    
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(val, index) in getListMenuByApp"
                    :key="index"
                    @click="upConfigUser(val.title,val.channels, item)">
                    <v-list-item-title >{{ val.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <user-list5
        :darkmode="darkmode"
        :dialog="dialogListUser"
        :users="userChild5"
        :data-request="data5"
        v-on:getData5="getUserChild5(item)"
        @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <div class="card">
        <div class="card-header">
          <h4>Active(Login)/Suspend(Login)</h4>
        </div>
        <div class="card-body">
          This will effect this user and its children. Do you want to
          confirm?
        </div>
        <div class="card-footer text-right">
          <button
            class="btn btn-light mr-1"
            @click="_closeDialogSuspend"
          >
            Close
          </button>
          <button
            class="btn"
            :class="
              selectedUser && selectedUser.is_suspend
                ? 'btn-success'
                : 'btn-danger'
            "
            @click="_updateUserSuspendStatus()"
          >
            {{
              selectedUser && selectedUser.is_suspend
                ? "Active"
                : "Suspend"
            }}
          </button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="enableDialog" persistent max-width="500px">
      <div class="card">
        <div class="card-header">
          <h4>Enable(Betting)/Not Enable(Betting)</h4>
        </div>
        <div class="card-body">
          This will effect this user and its children. Do you want to
          confirm?
        </div>
        <div class="card-footer text-right">
          <button
            class="btn btn-light mr-1"
            @click="_closeDialogEnable"
          >
            Close
          </button>
          <button
            class="btn"
            :class="
              selectedUser && selectedUser.enable
                ? 'btn-danger'
                : 'btn-success'
            "
            @click="_updateUserEnableStatus()"
          >
            {{
              selectedUser && selectedUser.enable
                ? "Not Enable"
                : "Enable"
            }}
          </button>
        </div>
      </div>
    </v-dialog>
      <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    <config-user-data
      :darkmode="darkmode"
      :dialog="dialogConfigUserData"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      :user="objUser"
      v-on:submit="onChangeConfigUser"
      v-on:onCloseDialog="closeConfigUserDialog"
      v-on:changeTab="onChangeTab"
    />
  </div>
</template>

<script>
import UserList5 from "./Dialog5";
import configUserData from "../ConfigUserData.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {UserList5, configUserData},
  props: ["darkmode", "dialog", "users", "dataRequest","userp"],
  data: () => {
    return {
      dialogListUser: false,
      userChild5: [],
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      item: null,
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      },
      selectedUser:[],
    suspendDialog : false,
    enableDialog: false,
    waiting:false,
    objUser: null,
    limitConfig: {},
      configUserData: [],
      limitHighLevelUser: [],
      configHighLevelUserCompany: {},
      dialogConfigHighLevelUserCompany:false,
      loading:false,
      dialogConfigUserData: false,
      dialogConfigHighLevelUser: false,
      channel_type: 1
    }
  },
  computed: {
    headers() {
      return [
        { text: "Status", value: 'enable' },
        {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_name'},
        { text: this.$t('header.bank_account'), value: 'account_info' },
        { text: this.$t('header.balance'), value: 'balance' },
        { text: this.$t('header.role'), value: 'role_name' },
        { text: this.$t('header.created_at'), value: 'updatedAt' },
        { text: this.$t('header.updateConfig'), value: 'updateConfigUser' },
        
        { text: this.$t('header.actions'), value: 'actions', sortable: false },
      ];
    },
    ...mapGetters("$_user", ["getListMenuByApp"]),
  },
  methods: {
      currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    getUserChild5(item) {
      this.item = item
      this.data5.parentId = this.item._id
      this.data5.sortByFormat.length < 1 ? this.data5.sortBy = '_id' : this.data5.sortBy = this.data5.sortByFormat[0]
      this.data5.descendingFormat.length > 0 ? this.data5.descending = this.data5.descendingFormat[0] : this.data5.descending = true
      this.$request.post({
        url: 'users/getAllByUserId',
        data: this.data5
      }).then((res) => {
        if (res.data.code) {
          this.dialogListUser = true
          this.userChild5 = res.data.data
        }
      })
    },
    _openDialogSuspend(item){
      this.selectedUser = item
      this.suspendDialog = true
      
    },
    _openDialogEnable(item) {
      this.selectedUser = item;
      this.enableDialog = true;
    },
     _closeDialogSuspend(){
      this.selectedUser = []
      this.suspendDialog = false
    },
    _closeDialogEnable() {
      this.selectedUser = [];
      this.enableDialog = false;
    },
    async _updateUserEnableStatus() {
      this.waiting = true;
      let data = {
        enable: !this.selectedUser.enable,
        userId: this.selectedUser._id, 
        // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/enableUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.$emit("getData4");
            this._closeDialogEnable();
            this.waiting = false;
          } else {
            this.$emit("getData4");
            this._closeDialogEnable();
            this.waiting = false;
          }
        });
    },
    async _updateUserSuspendStatus(){
      this.waiting = true;
      let data = {
        is_suspend : !this.selectedUser.is_suspend,
        userId: this.selectedUser._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
           this.$emit('getData4')
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.$emit('getData4')
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        })},
        _openDailogConfig(item){
      let data = {user_id: item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompany = res.data.data;
          this.dialogConfigHighLevelUserCompany = true;
        });
    },
    async updateLimit(item) {
      await this.fetchListMenuByApp(item);
    },
    async onChangeTab(channel_type){
      await this.getLimitData(this.item,channel_type);
    },
    async getLimitData(item, channel_type) {
      let data = {
        currency_type: item.currency_type,
        _id: item.parent_id,
        channel_type: channel_type
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBettingByChannel",
        data: data,
      });
      this.objUser = item;
      this.limitConfig = response.data.data.object[0];
    },

    async onChangeConfigUser(payload) {
      this.loading = true;
      let dataLimit = this.configUserData.filter(channel => channel.channel_type == payload);
      let data;
      if (dataLimit.length > 0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        }
      }else{
        data = this.configUserData;
      }
      if (data._id) {
        await this.updateConfigUser({ vm: this, d: data });
        this.loading = false;
        // this.dialogConfigUserData = false;
      }
      this.$emit('getData4')
    },
    async upConfigUser(title,channels, user) {
      
      if(channels.includes(90) || channels.includes(95) || channels.includes(93) || channels.includes(94) || channels.includes(96)){
        await this.upConfigSlotLimit(channels);
      }else{
        let data = { currency_type: user.currency_type, _id: user._id,channels:channels };
        this.$request
          .post({
            url: "configUser/getConfigData",
            data: data,
          })
          .then((res) => {
            this.configUserData = res.data.data.object;
            this.channel_type = this.configUserData[0] ?this.configUserData[0].channel_type : 1;
            this.getLimitData(user,this.channel_type);
            this.dialogConfigUserData = true;
          });
      }
    },
    async upConfigSlotLimit(channels) {
      let data = { 
        _id: this.item._id ,
        channels:channels
      };
      this.$request
        .post({
          url: "configUser/getSlotConfig",
          data: data,
        })
        .then((res) => {
          this.configSlotLimitData = res.data.data.object;
          this.dialogConfigSlotLimitData = true;
        });
    },
    closeConfigUserDialog() {
      this.dialogConfigUserData = false;
    },
    ...mapActions("$_configUser", [
      "updateLimitSetting",
    ]),
    ...mapActions("$_user", [
      "updateConfigUser", "fetchListMenuByApp"
    ]),
  }
}
</script>

<style scoped>

</style>
